<template>
    <div class="chat-widget" v-show="!exitWidget">
        <div class="chat-widget--bubble" :class="{ '-hide': !isVisible }">
            <span @click="isVisible = !isVisible" class="exit-widget exit-message"><i class="fa fa-close"></i></span>
            <h1 class="chat-widget--header">{{ defaultTitle }}</h1>
            <p class="chat-widget--text">{{ message }}</p>
            <button @click="redirectMe">Abrir WhatsApp</button>
        </div>
        <a class="chat-widget--button" @click="isVisible = !isVisible">
          <span v-show="isVisible" @click="exitWidget = !exitWidget" class="exit-widget"><i class="fa fa-close"></i></span>
            <img src="../assets/whatsapp.png" width="40px" />
          </a>
        </div>
  </template>

  <script>
  export default {
    name: 'whatsapp-widget',
    props: {
      siteName: {
        type: String,
        default: ''
      },
      redirectUrl: {
        type: String,
        default: 'https://wa.me/message/EBA3D7Y6ITMUL1?autoload=1&app_absent=0'
      }
    },
    data() {
      return {
        isVisible: false,
        exitWidget: false,
        showMessage: true,
        ingressoParaTodosHour: 'Das 14h00 - 16h00',
        defaultTitle: 'Precisa de ajuda?',
        defaultHourMessage: 'De Segunda a Domingo, das 10h às 18h.'
      }
    },
    computed: {
      message () {
        return this.siteName === 'ingressoparatodos.com.br' ? this.ingressoParaTodosHour : this.defaultHourMessage;
      }
    },
    methods: {
      redirectMe () {
        window.location = this.redirectUrl;
      },
      toggleWidget() {
        this.isVisible = !this.isVisible;
      }
    },
  }
</script>
  
<style lang="scss" scoped>
$theme-color: #0daa16;;
$bubble-color: #f6f6f6;
$header-color: mix($theme-color, black, 90%);
$text-color: #666;
$link-color: #0e9f17;;
$border-radius: 4px;

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba($theme-color, 0.4); }
  75%, 100% { box-shadow: 0 0 0 2rem rgba($theme-color, 0); }
}

.exit-widget {
  position: absolute;
  color: $theme-color;
  cursor: pointer;
  z-index: 8001;
  left: -1.4rem;
  top: 1.5rem;
  font-size: 1.2em;
}

.exit-message {
  right: 0.3rem;
  top: 0.3rem;
  left: inherit;
}

.chat-widget {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 8000;

  button {
  padding: 0 1rem !important;
  cursor: pointer;
  color: $link-color;
  border: 1px solid $link-color;
  border-radius: $border-radius;
  line-height: 2rem;
  background-color: transparent;
  transition:
    background-color 350ms ease,
    color 350ms ease;
  
  &:hover {
    background-color: $link-color;
    color: white;
  }
}

  &--bubble {
    display: block;
    width: 240px;
    padding: 2rem !important;
    position: absolute;
    right: 0;
    bottom: 6rem;
    background-color: $bubble-color;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(black, 0.3);

    transform-origin: 90% 100%;
    
    transition:
      opacity 400ms ease,
      transform 400ms ease;
    
    opacity: 1;
    transform: translateY(0rem) scale(1, 1) skew(0deg, 0deg);

    &.-hide {
      opacity: 0;
      transform: translateY(3rem) scale(0, 0) skew(0deg, 50deg);
    }

    > * + * {
      margin-top: 1rem !important;
    }

    &::after {
      content: '';
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      right: 1.5rem;
      bottom: -0.7rem;
      background-color: $bubble-color;
      box-shadow: 2px 2px 2px rgba(black, 0.2);
      transform: rotate(45deg);
    }
  }

  &--header {
    font: {
      size: 1.4rem;
      weight: normal;
    }
    color: $header-color;
  }

  &--text {
    font-size: 0.875rem;
    color: $text-color;
  }


  &--button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 4.5rem;
    position: absolute;
    cursor: pointer;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    background-color: $theme-color;
    box-shadow:
      0 0 0 -2px rgba(black, 1),
      0 2px 4px 0 rgba(black, 0.3);
    transition: box-shadow 250ms ease;

    &:hover {
      box-shadow:
        0 16px 10px -8px rgba(black, 0.3),
        0 4px 10px 0 rgba(black, 0.2);
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: 50%;
      animation: pulse 2s ease-in-out 6;  
    }
    &::after {
      animation-delay: 0.5s;
    }
  }

  &--icon {
    width: 2.5rem;
    height: auto;

    path {
      fill: white;
    }
  }
}

  </style>
  